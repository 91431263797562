import { useWindowSize } from "@react-hook/window-size";

export const SCREEN_ORIENTATION_LANDSCAPE = "landscape";
export const SCREEN_ORIENTATION_PORTRAIT = "portrait";
export const SCREEN_SIZE_EXTRA_SMALL = "extra-small";
export const SCREEN_SIZE_SMALL = "small";
export const SCREEN_SIZE_MEDIUM = "medium";
export const SCREEN_SIZE_LARGE = "large";
export const SCREEN_SIZE_EXTRA_LARGE = "extra-large";

const useScreenSizeAndOrientation = () => {
  const [width, height] = useWindowSize();

  let size = SCREEN_SIZE_EXTRA_SMALL;
  let orientation =
    width > height ? SCREEN_ORIENTATION_LANDSCAPE : SCREEN_ORIENTATION_PORTRAIT;
  if (width > 1200) {
    size = SCREEN_SIZE_EXTRA_LARGE;
  } else if (width > 992) {
    size = SCREEN_SIZE_LARGE;
  } else if (width > 768) {
    size = SCREEN_SIZE_MEDIUM;
  } else if (width > 576) {
    size = SCREEN_SIZE_SMALL;
  }

  return { size: size, orientation: orientation };
};

export default useScreenSizeAndOrientation;
