import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Card } from "antd";

const Terms = () => {
  useEffect(() => {
    document.title = "Support | TLDR Parents";
  }, []);

  return (
    <Fade>
      <h1 className="main-title" style={{ textAlign: "center" }}>
        Support
      </h1>
      <Card>
        {/*<h2 className="second-title">Support</h2>*/}
        <p>If you need help, please email support [at] tldrparents.com.</p>
      </Card>
    </Fade>
  );
};

export default Terms;
