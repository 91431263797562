import React from "react";
import { Card, Col, Row } from "antd";

const HomePageBenefitCard = ({ emoji, text }) => {
  return (
    <Card
      style={{
        border: "1px solid #00cc44",
        height: "100%",
      }}
    >
      <Row wrap={false}>
        <Col style={{ fontSize: "1.5rem", marginRight: 10 }}>{emoji}</Col>
        <Col style={{ fontSize: "1.5rem" }}>{text}</Col>
      </Row>
    </Card>
  );
};

export default HomePageBenefitCard;
