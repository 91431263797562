import React from "react";
import { Button, Card, Col, Row, Space } from "antd";
import { useNavigate } from "react-router-dom";
import useLoginButton from "../../hooks/useLoginButton";
import DoubleOrSingleCol from "../utilityComponents/doubleOrSingleCol";
import HomePageBenefitCard from "../utilityComponents/homePageBenefitCard";

const SimplifyYourLife = () => {
  const navigate = useNavigate();
  const { onClickLogin } = useLoginButton();

  return (
    <Row>
      <Col style={{ textAlign: "left" }}>
        <h2 className={"second-title"} style={{ color: "#00cc44" }}>
          Simplify at least one thing in your life with TLDR Parents
        </h2>
      </Col>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
        xl={{ span: 24 }}
        xxl={{ span: 24 }}
        style={{
          width: "100%",
        }}
      >
        <Row gutter={[16, 16]}>
          <DoubleOrSingleCol>
            <HomePageBenefitCard
              emoji={"📅"}
              text={
                "Magically turn school and extracurricular emails into interactive calendar events"
              }
            />
          </DoubleOrSingleCol>
          <DoubleOrSingleCol>
            <HomePageBenefitCard
              emoji={"🔝"}
              text={"Stay on top of everything (without reading everything)"}
            />
          </DoubleOrSingleCol>
          <DoubleOrSingleCol>
            <HomePageBenefitCard
              emoji={"🔖"}
              text={
                "With a single click, easily save important parts (e.g., links, images, attachments) of emails for later"
              }
            />
          </DoubleOrSingleCol>
          <DoubleOrSingleCol>
            <HomePageBenefitCard
              emoji={"🤝"}
              text={"Collaborate with a partner and avoid duplication of work"}
            />
          </DoubleOrSingleCol>
          <DoubleOrSingleCol>
            <HomePageBenefitCard
              emoji={"🔍"}
              text={"Discover fun stuff you didn't even know about"}
            />
          </DoubleOrSingleCol>
          <DoubleOrSingleCol>
            <Card
              style={{
                border: "1px solid #00cc44",
                height: "100%",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Space>
                  <Button
                    size="large"
                    type={"primary"}
                    ghost={true}
                    onClick={() => {
                      navigate("/features");
                    }}
                  >
                    Explore features
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => {
                      onClickLogin();
                    }}
                  >
                    Get started for FREE
                  </Button>
                </Space>
              </div>
            </Card>
          </DoubleOrSingleCol>
        </Row>
      </Col>
    </Row>
  );
};

export default SimplifyYourLife;
