import React from "react";
import { Col } from "antd";

const DoubleOrSingleCol = ({ children }) => {
  return (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 12 }}
      lg={{ span: 12 }}
      xl={{ span: 12 }}
      xxl={{ span: 12 }}
      style={{
        width: "100%",
      }}
    >
      {children}
    </Col>
  );
};

export default DoubleOrSingleCol;
