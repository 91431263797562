import React, { useState } from "react";
import { Button, Drawer, Space } from "antd";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import useLoginButton from "../../hooks/useLoginButton";

const NavMenu = () => {
  const [navDrawerOpen, setNavDrawerOpen] = useState(false);
  const { onClickLogin } = useLoginButton();

  const getHorizontalMenu = () => {
    return (
      <div id="navmenu-horizontal">
        <Space size={30} style={{ marginRight: 20 }}>
          <Link to="/features" className="top-nav-link">
            Features
          </Link>
          <Link to="/about" className="top-nav-link">
            About
          </Link>
          <Link to="/tips" className="top-nav-link">
            Tips & Videos
          </Link>
          <a
            className="top-nav-link ga-sign-up-button"
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              onClickLogin();
            }}
          >
            Sign In
          </a>
          <Button
            className={"ga-sign-up-button"}
            type="primary"
            size={"large"}
            onClick={() => {
              onClickLogin();
            }}
          >
            Sign up for FREE
          </Button>
        </Space>
      </div>
    );
  };

  const getDrawerHamburger = () => {
    return (
      <div id="navmenu-drawer" style={{ marginTop: 8, marginRight: 20 }}>
        <MenuOutlined
          style={{ fontSize: 35 }}
          onClick={() => setNavDrawerOpen(true)}
        />
      </div>
    );
  };

  const getDrawerContents = () => {
    return (
      <Space direction="vertical">
        <p>
          <Link
            to="/"
            onClick={() => setNavDrawerOpen(false)}
            className="top-nav-link"
          >
            Home
          </Link>
        </p>
        <p>
          <Link
            to="/features"
            onClick={() => setNavDrawerOpen(false)}
            className="top-nav-link"
          >
            Features
          </Link>
        </p>
        <p>
          <Link
            to="/about"
            onClick={() => setNavDrawerOpen(false)}
            className="top-nav-link"
          >
            About
          </Link>
        </p>
        <p>
          <Link
            to="/tips"
            onClick={() => setNavDrawerOpen(false)}
            className="top-nav-link"
          >
            Tips & Videos
          </Link>
        </p>
        <p>
          <a
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              setNavDrawerOpen(false);
              onClickLogin();
            }}
            className="top-nav-link"
          >
            Sign In
          </a>
        </p>
        <p>
          <Button
            className={"ga-sign-up-button"}
            onClick={() => {
              setNavDrawerOpen(false);
              onClickLogin();
            }}
            type="primary"
          >
            Sign up for FREE
          </Button>
        </p>
      </Space>
    );
  };

  return (
    <>
      <Drawer
        width={300}
        closable={false}
        placement="right"
        open={navDrawerOpen}
        onClose={() => setNavDrawerOpen(false)}
      >
        {getDrawerContents()}
      </Drawer>
      {getHorizontalMenu()}
      {getDrawerHamburger()}
    </>
  );
};

export default NavMenu;
