import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import TopHeader from "../utilityComponents/topHeader";
import SpaceAroundCardWithColorBorder from "../utilityComponents/spaceAroundCardWithColorBorder";
import { Card, Col, Collapse, Row } from "antd";
import addToHomeScreen1 from "../../images/add-to-phone-home-1.png";
import addToHomeScreen2 from "../../images/add-to-phone-home-2.png";
import addToHomeScreen3 from "../../images/add-to-phone-home-3.png";

const TipsAndMore = () => {
  useEffect(() => {
    document.title = "Tips, Videos & FAQ | TLDR Parents";
  }, []);

  const tipItems = [
    {
      key: "1",
      label: "Suggested Workflow",
      children: (
        <p style={{ fontSize: "1.25rem" }}>
          If you're looking for workflow to get you started, we suggest watching{" "}
          <a
            rel="noreferrer"
            href={"https://www.youtube.com/watch?v=6ZC8N7leT90"}
            target={"_blank"}
          >
            this video
          </a>
          .
        </p>
      ),
    },
    {
      key: "2",
      label: "Add TLDR Parents to you phone's home screen",
      children: (
        <>
          <p style={{ fontSize: "1.25rem" }}>
            We do not have a mobile app yet. However, with the trick below, you
            can get something almost as good. Follow the instructions to add our
            icon to your home screen and access our site in a dedicated browser.
            (Instructions for Android coming soon.)
          </p>
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <Card
                style={{ height: "100%" }}
                hoverable
                cover={<img alt={"Add to phone"} src={addToHomeScreen1} />}
              >
                <Card.Meta
                  title="Step 1"
                  description="Go to app.tldrparents.com in your browser. Click the share button."
                />
              </Card>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <Card
                style={{ height: "100%" }}
                hoverable
                cover={<img alt={"Add to phone"} src={addToHomeScreen2} />}
              >
                <Card.Meta
                  title="Step 2"
                  description='Click "Add to Home Screen".'
                />
              </Card>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <Card
                style={{ height: "100%" }}
                hoverable
                cover={<img alt={"Add to phone"} src={addToHomeScreen3} />}
              >
                <Card.Meta
                  title="Step 3"
                  description='Make sure the URL is the same as above and click "Add".'
                />
              </Card>
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "3",
      label: "Invite a partner",
      children: (
        <>
          <p style={{ fontSize: "1.25rem" }}>
            TLDR Parents provides a shared workspace for parents and other
            caregivers. This is super handy for several reasons:
          </p>
          <ul style={{ fontSize: "1.25rem" }}>
            <li>
              <b>Shared Communication:</b> Sometimes only one parent gets emails
              from an activity because they were the one to sign up. Forward
              those to TLDR Parents so your partner can also have access.{" "}
            </li>
            <li>
              <b>Collaborative Task Management:</b> TLDR Parents provides a
              collaborative task management workspace that can help you avoid
              doing duplicate work. No need to fill out the field trip form
              twice!
            </li>
          </ul>
        </>
      ),
    },
    // {
    //   key: "4",
    //   label: "Automatically forward emails with rules",
    //   children: <p style={{ fontSize: "1.25rem" }}>Text</p>,
    // },
  ];

  const videoItems = [
    {
      key: "1",
      label: "TLDR Parents Trailer",
      children: (
        <div style={{ width: "100%", maxWidth: 600 }}>
          <div className={"iframe-wrapper"}>
            <iframe
              src="https://www.youtube.com/embed/yKS03QWh-OI?si=JDPMY5WoylRVrdkB"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Suggested Workflow Demo",
      children: (
        <div style={{ width: "100%", maxWidth: 600 }}>
          <div className={"iframe-wrapper"}>
            <iframe
              src="https://www.youtube.com/embed/6ZC8N7leT90?si=ls3iqexCg3QyYaKq"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: "Auto-forwarding select messages from Gmail",
      children: (
        <div style={{ width: "100%", maxWidth: 600 }}>
          <div className={"iframe-wrapper"}>
            <iframe
              src="https://www.youtube.com/embed/-G84ZbGkay0?si=xop78x-II0m58WxW"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      ),
    },
  ];

  const faqItems = [
    {
      key: "1",
      label: "How do I get emails into TLDR Parents?",
      children: (
        <p style={{ fontSize: "1.25rem" }}>
          Forward emails to{" "}
          <span style={{ color: "#f06292" }}>email@tldrparents.com</span> using
          the email address you signed up with. Emails from all members of your
          family workspace will be routed to the same shared inbox.
        </p>
      ),
    },
    {
      key: "2",
      label: "What types of messages should I send to TLDR Parents?",
      children: (
        <>
          <p style={{ fontSize: "1.25rem" }}>
            TLDR Parents is most helpful on longer emails that describe events
            and/or have a few links. Here are a few suggestions:
          </p>
          <ul style={{ fontSize: "1.25rem" }}>
            <li>School newsletters (anything from smore.com!)</li>
            <li>Sports teams</li>
            <li>Music programs</li>
            <li>Summer camps</li>
            <li>Extracurricular academic programs</li>
            <li>Regular emails from churches, temples, mosques, etc.</li>
            <li>Regular emails from YMCA / JCC, etc.</li>
          </ul>
          <p style={{ fontSize: "1.25rem" }}>
            It’s also helpful for some emails that are not directly related to
            parenting. For example, we’ve seen folks forward emails from their
            town’s mayor.
          </p>
        </>
      ),
    },
  ];

  return (
    <Fade>
      <TopHeader header={"Tips, Videos & FAQs"} />
      <div style={{ height: 20 }} />
      <Row
        justify="space-around"
        style={{
          padding: "0 10px",
          backgroundColor: "#fff",
          width: "100%",
        }}
      >
        <Col
          style={{
            maxWidth: 1400,
            width: "100%",
          }}
        >
          <h2
            className={"second-title"}
            style={{ color: "#168dff", marginBottom: 0 }}
          >
            Tips
          </h2>
        </Col>
      </Row>
      <SpaceAroundCardWithColorBorder borderColor={"#168dff"}>
        <Collapse size="large" items={tipItems} />
      </SpaceAroundCardWithColorBorder>
      <div style={{ height: 30 }} />

      <Row
        justify="space-around"
        style={{
          padding: "0 10px",
          backgroundColor: "#fff",
          width: "100%",
        }}
      >
        <Col
          style={{
            maxWidth: 1400,
            width: "100%",
          }}
        >
          <h2
            className={"second-title"}
            style={{ color: "#00cc44", marginBottom: 0 }}
          >
            Videos
          </h2>
        </Col>
      </Row>
      <SpaceAroundCardWithColorBorder borderColor={"#00cc44"}>
        <Collapse size="large" items={videoItems} />
      </SpaceAroundCardWithColorBorder>
      <div style={{ height: 30 }} />

      <Row
        justify="space-around"
        style={{
          padding: "0 10px",
          backgroundColor: "#fff",
          width: "100%",
        }}
      >
        <Col
          style={{
            maxWidth: 1400,
            width: "100%",
          }}
        >
          <h2
            className={"second-title"}
            style={{ color: "#9c27b0", marginBottom: 0 }}
          >
            FAQs
          </h2>
        </Col>
      </Row>
      <SpaceAroundCardWithColorBorder borderColor={"#9c27b0"}>
        <Collapse size="large" items={faqItems} />
      </SpaceAroundCardWithColorBorder>
      <div style={{ height: 30 }} />
    </Fade>
  );
};

export default TipsAndMore;
