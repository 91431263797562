import React, { useEffect } from "react";
import { Button, Image, Row } from "antd";
import productHome from "../../images/product-home.png";
import eventsGenie from "../../images/events-genie.png";
import addToGoogleCalendar from "../../images/add-to-google-calendar.png";
import filesAndLinks from "../../images/files-and-links.png";
import createTodos from "../../images/create-todos.png";
import pinToBoards from "../../images/pin-to-boards.png";
import sharedInbox from "../../images/shared-inbox.png";
import logo from "../../images/logo-512.png";
import ResponsiveColumn from "../utilityComponents/responsiveColumn";
import Fade from "react-reveal/Fade";
import useLoginButton from "../../hooks/useLoginButton";
import TopHeader from "../utilityComponents/topHeader";
import SpaceAroundCardWithColorBorder from "../utilityComponents/spaceAroundCardWithColorBorder";

const Features = () => {
  const { onClickLogin } = useLoginButton();

  useEffect(() => {
    document.title = "TLDR Parents Features";
  }, []);

  const getSectionHomePage = () => {
    return (
      <SpaceAroundCardWithColorBorder borderColor={"#00cc44"}>
        <Row align="middle" gutter={[16, 16]}>
          <ResponsiveColumn
            largeSpan={12}
            largeOrder={1}
            smallSpan={24}
            smallOrder={1}
          >
            <h2 className="second-title" style={{ color: "#00cc44" }}>
              Experience School Emails Like Never Before!
            </h2>
            <p style={{ fontSize: "1.2rem" }}>
              Welcome to your new favorite way to organize emails from schools
              and extracurriculars! Our home screen is your dashboard, divided
              into two awesome tabs:
            </p>

            <h3 style={{ fontSize: "1.4rem" }}>
              🕵️ Discovery Tab: Unlock Hidden Treasures!
            </h3>
            <ul style={{ fontSize: "1.2rem" }}>
              <li>
                <strong>Smart Inbox:</strong> A shared inbox providing
                bullet-point summaries and calendar ready events extracted from
                each message.
              </li>
              <li>
                <strong>Events Genie:</strong> Never miss out! Discover all the
                exciting events coming up from across messages.
              </li>
              <li>
                <strong>Key Links and Files: </strong>Quick access to things
                that might require action like Google Docs and Forms.
              </li>
            </ul>

            <h3 style={{ fontSize: "1.4rem" }}>
              🚀 Action Tab: Get Things Done!
            </h3>
            <ul style={{ fontSize: "1.2rem" }}>
              <li>
                <strong>To-Dos:</strong> Tackle tasks collaboratively with a
                partner.
              </li>
              <li>
                <strong>Pinned Items:</strong> Easily locate files, links, and
                messages you've saved for later.
              </li>
            </ul>
            <p style={{ fontSize: "1.2rem" }}>
              Dive in and transform the way you organize school and
              extracurricular activities.
            </p>
          </ResponsiveColumn>
          <ResponsiveColumn
            largeSpan={12}
            largeOrder={2}
            smallSpan={24}
            smallOrder={2}
            style={{ textAlign: "center" }}
          >
            <Image className={"screenshot"} src={productHome} />
          </ResponsiveColumn>
        </Row>
      </SpaceAroundCardWithColorBorder>
    );
  };

  const howToGetMessagesIntoTldrParents = () => {
    return (
      <SpaceAroundCardWithColorBorder borderColor={"#168dff"}>
        <Row align="middle" gutter={[16, 16]}>
          <ResponsiveColumn
            largeSpan={24}
            largeOrder={1}
            smallSpan={24}
            smallOrder={1}
          >
            <h2 className="second-title" style={{ color: "#168dff" }}>
              FAQ: How do messages get into TLDR Parents
            </h2>
            <p style={{ fontSize: "1.2rem" }}>
              To send a message to TLDR Parents, simply forward it to{" "}
              <span style={{ color: "#f06292" }}>email@tldrparents.com</span>.
              If you're familiar with creating rules, you can easily build a
              filter to automatically forward certain messages.
            </p>
          </ResponsiveColumn>
        </Row>
      </SpaceAroundCardWithColorBorder>
    );
  };

  const getSharedInboxSection = () => {
    return (
      <SpaceAroundCardWithColorBorder borderColor={"#9c27b0"}>
        <Row align="middle" gutter={[16, 16]}>
          <ResponsiveColumn
            largeSpan={12}
            largeOrder={1}
            smallSpan={24}
            smallOrder={1}
          >
            <h2 className="second-title" style={{ color: "#9c27b0" }}>
              Team up for Success!
            </h2>
            <h3 style={{ fontSize: "1.4rem" }}>
              👫 Collaborate with a Partner
            </h3>
            <p style={{ fontSize: "1.2rem" }}>
              When you forward messages to TLDR Parents, they land in a shared
              inbox, making it super easy for you and your partner to stay in
              the loop together.
            </p>
            <p style={{ fontSize: "1.2rem" }}>
              If one of you signs the kids up for an activity, the other might
              miss out on those email updates. No worries—simply forward those
              messages to TLDR Parents. Now, both of you can read and act on
              them, keeping everyone on the same page!
            </p>
          </ResponsiveColumn>
          <ResponsiveColumn
            largeSpan={12}
            largeOrder={2}
            smallSpan={24}
            smallOrder={2}
            style={{ textAlign: "center" }}
          >
            <Image className={"screenshot"} src={sharedInbox} />
          </ResponsiveColumn>
        </Row>
      </SpaceAroundCardWithColorBorder>
    );
  };

  const getSectionEventsGenie1 = () => {
    return (
      <SpaceAroundCardWithColorBorder borderColor={"#ff6f00"}>
        <Row align="middle" gutter={[16, 16]}>
          <ResponsiveColumn
            largeSpan={12}
            largeOrder={1}
            smallSpan={24}
            smallOrder={1}
          >
            <h2 className="second-title" style={{ color: "#ff6f00" }}>
              Browse Events from All Messages in One Place
            </h2>
            <h3 style={{ fontSize: "1.4rem" }}>
              🧞 <strong>The Events Genie</strong> works its magic by pulling
              events from all your messages and displaying them in one
              easy-to-navigate timeline.
            </h3>
            <p style={{ fontSize: "1.2rem" }}>Here’s how it works:</p>
            <ul style={{ fontSize: "1.2rem" }}>
              <li>
                <strong>Chronological View:</strong> See all your events lined
                up in order.
              </li>
              <li>
                <strong>Event Cards:</strong> Each card includes a link back to
                the original message for quick reference and context.
              </li>
              <li>
                <strong>Customizable:</strong> Hide events you’re not interested
                in and add important ones to your Google Calendar with a single
                click.
              </li>
            </ul>
            <p style={{ fontSize: "1.2rem" }}>
              Stay organized and never miss a beat with the Events Genie!
            </p>
          </ResponsiveColumn>
          <ResponsiveColumn
            largeSpan={12}
            largeOrder={2}
            smallSpan={24}
            smallOrder={2}
            style={{ textAlign: "center" }}
          >
            <Image className={"screenshot"} src={eventsGenie} />
          </ResponsiveColumn>
        </Row>
      </SpaceAroundCardWithColorBorder>
    );
  };

  const getSectionEventsGenie2 = () => {
    return (
      <SpaceAroundCardWithColorBorder borderColor={"#ff4081"}>
        <Row align="middle" gutter={[16, 16]}>
          <ResponsiveColumn
            largeSpan={12}
            largeOrder={1}
            smallSpan={24}
            smallOrder={1}
          >
            <h2 className="second-title" style={{ color: "#ff4081" }}>
              Add Events to Google Calendar with a Click
            </h2>
            <h3 style={{ fontSize: "1.4rem" }}>
              📅 TLDR Parents prefills the details.
            </h3>
            <ul style={{ fontSize: "1.2rem" }}>
              <li>
                <strong>One-Click Add:</strong> Quickly add events to your
                Google Calendar with just a click.
              </li>
              <li>
                <strong>Editable Details:</strong> Customize the event fields as
                needed.
              </li>
              <li>
                <strong>Embedded Source Link:</strong> We automatically embed a
                link to the original message in the Google Calendar event. This
                is perfect for getting more context about the event later or
                when you're on the go.
              </li>
            </ul>
            <p style={{ fontSize: "1.2rem" }}>
              Keep everything organized and easily accessible with this handy
              feature!
            </p>
          </ResponsiveColumn>
          <ResponsiveColumn
            largeSpan={12}
            largeOrder={2}
            smallSpan={24}
            smallOrder={2}
            style={{ textAlign: "center" }}
          >
            <Image className={"screenshot"} src={addToGoogleCalendar} />
          </ResponsiveColumn>
        </Row>
      </SpaceAroundCardWithColorBorder>
    );
  };

  const getSectionFilesAndLinks = () => {
    return (
      <SpaceAroundCardWithColorBorder borderColor={"#168dff"}>
        <Row align="middle" gutter={[16, 16]}>
          <ResponsiveColumn
            largeSpan={12}
            largeOrder={1}
            smallSpan={24}
            smallOrder={1}
          >
            <h2 className="second-title">
              Browse Key Links and Files in One Place
            </h2>
            <h3 style={{ fontSize: "1.4rem" }}>
              🔗 We make it easy to find all your important files and links by
              extracting them from your messages and generating graphical
              previews.
            </h3>
            <p style={{ fontSize: "1.2rem" }}>Here’s what you get:</p>
            <ul style={{ fontSize: "1.2rem" }}>
              <li>
                <strong>Attachments and Links:</strong> All your files and links
                from across messages in one place.
              </li>
              <li>
                <strong>Graphical Previews: </strong>Easily identify links with
                visual previews.
              </li>
              <li>
                <strong>Tagging:</strong> Items are tagged for easy filtering
                and access.
              </li>
              <li>
                <strong>Important Items Highlighted:</strong> Key items like
                maps, Zoom links, Google Docs and Forms, PDFs, and surveys are
                shown prominently by default.
              </li>
            </ul>
            <p style={{ fontSize: "1.2rem" }}>
              Come here to find all the things you forgot to fill out and stay
              on top of your tasks effortlessly.
            </p>
          </ResponsiveColumn>
          <ResponsiveColumn
            largeSpan={12}
            largeOrder={2}
            smallSpan={24}
            smallOrder={2}
            style={{ textAlign: "center" }}
          >
            <Image className={"screenshot"} src={filesAndLinks} />
          </ResponsiveColumn>
        </Row>
      </SpaceAroundCardWithColorBorder>
    );
  };

  const getSectionTodos = () => {
    return (
      <SpaceAroundCardWithColorBorder borderColor={"#00cc44"}>
        <Row align="middle" gutter={[16, 16]}>
          <ResponsiveColumn
            largeSpan={12}
            largeOrder={1}
            smallSpan={24}
            smallOrder={1}
          >
            <h2 className="second-title" style={{ color: "#00cc44" }}>
              Create To-Dos from Parts of Messages
            </h2>
            <h3 style={{ fontSize: "1.4rem" }}>
              ✅ Anything in TLDR Parents can become the basis of a to-do or be
              pinned to a board. This includes:
            </h3>
            <ul style={{ fontSize: "1.2rem" }}>
              <li>Files</li>
              <li>Images</li>
              <li>Links</li>
              <li>Email Addresses</li>
              <li>Message Topics</li>
              <li>Entire messages</li>
            </ul>
            <p style={{ fontSize: "1.2rem" }}>
              Want to remind your partner to complete a form? With just a couple
              clicks, you can create a to-do for them that links to the specific
              form and the source message.
            </p>
          </ResponsiveColumn>
          <ResponsiveColumn
            largeSpan={12}
            largeOrder={2}
            smallSpan={24}
            smallOrder={2}
            style={{ textAlign: "center" }}
          >
            <Image className={"screenshot"} src={createTodos} />
          </ResponsiveColumn>
        </Row>
      </SpaceAroundCardWithColorBorder>
    );
  };

  const getSectionBoards = () => {
    return (
      <SpaceAroundCardWithColorBorder borderColor={"#9c27b0"}>
        <Row align="middle" gutter={[16, 16]}>
          <ResponsiveColumn
            largeSpan={12}
            largeOrder={1}
            smallSpan={24}
            smallOrder={1}
          >
            <h2 className="second-title" style={{ color: "#9c27b0" }}>
              Pin the Important Stuff to Shared Boards
            </h2>
            <h3 style={{ fontSize: "1.4rem" }}>
              📌 School emails often include links to things you want to save
              for later.
            </h3>
            <p style={{ fontSize: "1.2rem" }}>
              Instead of archiving the message and searching your inbox in
              frustration later, try this better way: Pin the item to a board in
              TLDR Parents.
            </p>
            <ul style={{ fontSize: "1.2rem" }}>
              <li>
                <strong>Define Categories:</strong> Create a board for your pins
                based on subject (e.g., "Summer Camp").
              </li>
              <li>
                <strong>Pin Relevant Stuff:</strong> Pin files, links, and
                messages that contain important info like phone numbers, maps,
                and links to mobile apps.
              </li>
            </ul>
            <p style={{ fontSize: "1.2rem" }}>
              Stay organized and easily find the important information whenever
              you need it.
            </p>
          </ResponsiveColumn>
          <ResponsiveColumn
            largeSpan={12}
            largeOrder={2}
            smallSpan={24}
            smallOrder={2}
            style={{ textAlign: "center" }}
          >
            <Image className={"screenshot"} src={pinToBoards} />
          </ResponsiveColumn>
        </Row>
      </SpaceAroundCardWithColorBorder>
    );
  };

  const getSectionFeelOrganized = () => {
    return (
      <SpaceAroundCardWithColorBorder borderColor={"#ff6f00"}>
        <Row align="middle" gutter={[16, 16]}>
          <ResponsiveColumn
            largeSpan={12}
            largeOrder={1}
            smallSpan={24}
            smallOrder={1}
          >
            <h2 className="second-title" style={{ color: "#ff6f00" }}>
              Feel organized and happy!
            </h2>
            <p style={{ fontSize: "1.2rem" }}>
              😊 Our mission is to create intelligent, AI-driven software that
              keeps parents informed, organized, and happy with minimal effort.
            </p>
          </ResponsiveColumn>
          <ResponsiveColumn
            largeSpan={12}
            largeOrder={2}
            smallSpan={24}
            smallOrder={2}
            style={{ textAlign: "center" }}
          >
            <Image className={"screenshot"} src={logo} />
          </ResponsiveColumn>
        </Row>
      </SpaceAroundCardWithColorBorder>
    );
  };

  return (
    <Fade>
      <TopHeader header={"Meet your new best friend for school emails!"} />
      <div style={{ height: 20 }} />
      {getSectionHomePage()}
      <div style={{ height: 20 }} />
      {howToGetMessagesIntoTldrParents()}
      <div style={{ height: 20 }} />
      {getSharedInboxSection()}
      <div style={{ height: 20 }} />
      {getSectionEventsGenie1()}
      <div style={{ height: 20 }} />
      {getSectionEventsGenie2()}
      <div style={{ height: 20 }} />
      {getSectionFilesAndLinks()}
      {getSectionTodos()}
      <div style={{ height: 20 }} />
      {getSectionBoards()}
      <div style={{ height: 20 }} />
      {getSectionFeelOrganized()}
      <div style={{ height: 20 }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: 30,
        }}
      >
        <Button
          type="primary"
          className="btn--accent ga-sign-up-button"
          onClick={() => {
            onClickLogin();
          }}
        >
          Try it for FREE
        </Button>
      </div>
    </Fade>
  );
};

export default Features;
