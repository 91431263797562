import { RouterProvider } from "react-router-dom";
import router from "./routes";
import React, { useEffect } from "react";
import { App as AntDApp, ConfigProvider } from "antd";
import ReactGA from "react-ga4";
import { H } from "highlight.run";

H.init("odzwmxld", {
  serviceName: "frontend-app",
  tracingOrigins: true,
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
    urlBlocklist: [
      // insert full or partial urls that you don't want to record here
      // Out of the box, Highlight will not record these URLs (they can be safely removed):
      "https://www.googleapis.com/identitytoolkit",
      "https://securetoken.googleapis.com",
    ],
  },
});

function App() {
  // useEffect(() => {
  //   ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);
  // }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#168dff",
          colorBgLayout: "#168dff",
          colorLink: "#168dff",
          colorTextBase: "#2f2f2f",
          fontFamily: "Jost",
          fontWeightStrong: 700,
        },
        components: {
          Spin: {
            colorPrimary: "#ffffff",
            algorithm: true,
          },
          Button: {
            borderRadius: 20,
            borderRadiusLG: 28,
            borderRadiusSM: 8,
          },
        },
      }}
    >
      <AntDApp>
        <RouterProvider router={router} />
      </AntDApp>
    </ConfigProvider>
  );
}

export default App;
