import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import TopHeader from "../utilityComponents/topHeader";
import SpaceAroundCardWithColorBorder from "../utilityComponents/spaceAroundCardWithColorBorder";

const GoogleApiDisclosure = () => {
  useEffect(() => {
    document.title = "Google API Disclosure | TLDR Parents";
  }, []);

  return (
    <Fade>
      <TopHeader header={"Google API Services Usage Disclosure"} />
      <SpaceAroundCardWithColorBorder borderColor={"#ff4081"}>
        <h3>
          TLDR Parents' use of information received from Google APIs will adhere
          to{" "}
          <a
            href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
            target="_blank"
          >
            Google API Services User Data Policy
          </a>
          , including the Limited Use requirements.
        </h3>
        <h3>Limited Use</h3>
        <p>
          Our app strictly complies with all conditions specified in the limited
          use policy of Google.
        </p>
        <ul>
          <li>
            Do not allow humans to read the user's data unless you have obtained
            the user's affirmative agreement to view specific messages, files,
            or other data.
          </li>
          <li>
            Do not use or transfer the data for serving ads, including
            retargeting, personalized, or interest-based advertising; and
          </li>
          <li>
            Limit your use of data to providing or improving user-facing
            features that are prominent in the requesting application's user
            interface. All other uses of the data are prohibited;
          </li>
          <li>
            Only transfer the data to others if necessary to provide or improve
            user-facing features that are prominent in the requesting
            application's user interface.
          </li>
        </ul>
        <p>
          Our privacy policy page documents in detail what data our app is
          requesting and why the requests access to Google user data.
        </p>
      </SpaceAroundCardWithColorBorder>
      <div style={{ height: 30 }} />
    </Fade>
  );
};

export default GoogleApiDisclosure;
