import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import TopHeader from "../utilityComponents/topHeader";
import SpaceAroundCardWithColorBorder from "../utilityComponents/spaceAroundCardWithColorBorder";
import { Col, Row } from "antd";

const About = () => {
  useEffect(() => {
    document.title = "About TLDR Parents";
  }, []);

  return (
    <Fade>
      <TopHeader header={"Why I started TLDR Parents"} />
      <div style={{ height: 20 }} />
      <SpaceAroundCardWithColorBorder borderColor={"#00cc44"}>
        <Row>
          <Col style={{ fontSize: "1.25rem" }}>
            <p>Hey there, and thanks for stopping by!</p>
            <p>
              I’m John – a software engineer, husband, and co-ringmaster of a
              family circus that includes two kids (girl nine, boy twelve) and
              one Bernese Mountain Dog (age two) who’s convinced he’s a lap dog.
              TLDR Parents was born out of a few experiences and observations.
              Maybe some of them will resonate with you!
            </p>
            <br />

            <h3>Busy, Sometimes Overwhelmed Parent</h3>
            <p>
              My wife and I are busy, working parents trying to do right by our
              kids. Between emails from two schools, four soccer teams, a swim
              team, a gymnastics league, and a couple of extracurricular
              language classes, processing our inboxes is practically a second
              job. In the past year alone, we've received over 1,400 emails
              related to our kids' schools and activities. We keep up most of
              the time, but we've also had our fair share of "whoops" moments.
              We’ve forgotten to fill out forms, missed parents' night once (or
              twice), shown up at the wrong soccer field, and completely bombed
              more than a few spirit/dress-up days. Keeping up feels like a
              chore. But it shouldn't be all pain and busywork. After all, these
              emails are required reading if we're going to give our kids all
              the opportunities they deserve. Shouldn’t the task offer a dash of
              delight? What if we could take away some of the copying and
              pasting and plow that time back into enjoying an update video from
              our kid’s teacher?
            </p>
            <br />

            <h3>More Than a Few Years Working in Tech</h3>
            <p>
              In a previous role as co-founder and CTO of a marketing software
              company, I dedicated a bunch of time to the task of extracting
              insights from raw, unstructured text data. I know, you're eyes are
              glazing over, so I'll keep this part short. It dawned on me that
              processing school emails poses a similar challenge: you’ve got to
              distill key information from a flood of text and turn it into
              actionable items as quickly and painlessly as possible.
            </p>
            <br />

            <h3>
              Part Running a Family <i>is</i> Project Management
            </h3>
            <p>
              At some point in the past five years (it’s all a bit of a blur), I
              made a note-to-self: Why don't more parents use the same tools to
              manage their households that they use for work projects? Better
              yet, why aren’t there more project management tools specifically
              designed for family management? After all, overseeing kids’
              schools and activities is a complex project, filled with events
              and tasks, and usually requires collaboration (and sometimes a
              miracle or two).
            </p>
            <p>
              I poked around for household management solutions on the market,
              and found a few, but they all fell short in my opinion. Each
              offered a nice framework and interface for things like calendars
              and to-dos, but none truly made the job easier by automating some
              of the tedious busywork. What I wanted was a purpose-built product
              that would keep me up to date without reading every line of every
              message, transform emails into calendar events, and help me
              collaborate on tasks with my spouse. Additionally, I wanted a
              product that was visual and allowed users to replace the process
              of copying and pasting with single-click selection.
            </p>
            <br />

            <h3>Serendipitously Coming Across a Tweet</h3>
            <p>
              During the Covid years, I stumbled upon a tweet from comedic
              writer/producer Joe Birbiglia: "Stepping down from my day job to
              devote myself full-time to reading the emails from my kid’s
              school." The tweet received 80k+ likes, and the comments were a
              mix of humor and solidarity, underscoring how many parents
              struggle to keep up with school emails. With this inspiration, my
              next entrepreneurial project started to come into focus.
            </p>
            <br />
            <p>
              I sincerely hope TLDR Parents will give you some time back to
              spend where it matters most.
            </p>
            <br />
            <div>
              John Osborne
              <br />
              Busy Parent & Founder, TLDR Parents
              <br />
              Summer 2024
            </div>
          </Col>
        </Row>
      </SpaceAroundCardWithColorBorder>
      <div style={{ height: 30 }} />
    </Fade>
  );
};

export default About;
