import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import SpaceAroundCardWithColorBorder from "../utilityComponents/spaceAroundCardWithColorBorder";
import TopHeader from "../utilityComponents/topHeader";

const Privacy = () => {
  useEffect(() => {
    document.title = "Privacy Policy | TLDR Parents";
  }, []);

  return (
    <Fade>
      <TopHeader header={"Privacy Policy of TLDR Parents, Inc."} />

      <SpaceAroundCardWithColorBorder borderColor={"#ff4081"}>
        <p>
          Protecting your private information is our priority. This Statement of
          Privacy applies to TLDR Parents, and TLDR Parents, Inc. and governs
          data collection and usage. For the purposes of this Privacy Policy,
          unless otherwise noted, all references to TLDR Parents, Inc. include
          https://tldrparents.com, https://app.tldrparents.com, and TLDR
          Parents. The TLDR Parents application is a parent organizer
          application. By using the TLDR Parents application, you consent to the
          data practices described in this statement.
        </p>

        <h2 className="second-title">
          Collection of your Personal Information
        </h2>
        <p>
          In order to better provide you with products and services offered,
          TLDR Parents may collect personally identifiable information, such as
          your:
        </p>
        <ul>
          <li>First and Last Name</li>
          <li>E-mail Address</li>
        </ul>
        <p>
          If you purchase TLDR Parents' products and services, we collect
          billing and credit card information. This information is used to
          complete the purchase transaction.
        </p>
        <p>
          We do not collect any personal information about you unless you
          voluntarily provide it to us. However, you may be required to provide
          certain personal information to us when you elect to use certain
          products or services. These may include: (a) registering for an
          account; (b) entering a sweepstakes or contest sponsored by us or one
          of our partners; (c) signing up for special offers from selected third
          parties; (d) sending us an email message; (e) submitting your credit
          card or other payment information when ordering and purchasing
          products and services. To wit, we will use your information for, but
          not limited to, communicating with you in relation to services and/or
          products you have requested from us. We also may gather additional
          personal or non-personal information in the future.
        </p>

        <h2 className="second-title">Use of your Personal Information</h2>
        <p>
          TLDR Parents collects and uses your personal information to operate
          and deliver the services you have requested.
        </p>
        <p>
          TLDR Parents may also use your personally identifiable information to
          inform you of other products or services available from TLDR Parents
          and its affiliates.
        </p>

        <h2 className="second-title">Sharing Information with Third Parties</h2>
        <p>
          TLDR Parents does not sell, rent or lease its customer lists to third
          parties.
        </p>
        <p>
          TLDR Parents may share data with trusted partners to help perform
          statistical analysis, send you email or postal mail, provide customer
          support, or arrange for deliveries. All such third parties are
          prohibited from using your personal information except to provide
          these services to TLDR Parents, and they are required to maintain the
          confidentiality of your information.
        </p>
        <p>
          TLDR Parents may disclose your personal information, without notice,
          if required to do so by law or in the good faith belief that such
          action is necessary to: (a) conform to the edicts of the law or comply
          with legal process served on TLDR Parents or the site; (b) protect and
          defend the rights or property of TLDR Parents; and/or (c) act under
          exigent circumstances to protect the personal safety of users of TLDR
          Parents, or the public.
        </p>

        <h2 className="second-title">Security of your Personal Information</h2>
        <p>
          TLDR Parents secures your personal information from unauthorized
          access, use, or disclosure. TLDR Parents uses the following methods
          for this purpose:
        </p>
        <ul>
          <li>AWS encryption</li>
        </ul>
        <p>
          We strive to take appropriate security measures to protect against
          unauthorized access to or alteration of your personal information.
          Unfortunately, no data transmission over the Internet or any wireless
          network can be guaranteed to be 100% secure. As a result, while we
          strive to protect your personal information, you acknowledge that: (a)
          there are security and privacy limitations inherent to the Internet
          which are beyond our control; and (b) security, integrity, and privacy
          of any and all information and data exchanged between you and us
          through this Site cannot be guaranteed.
        </p>

        <h2 className="second-title"> Right to Deletion</h2>
        <p>
          Subject to certain exceptions set out below, on receipt of a
          verifiable request from you, we will:
        </p>
        <ul>
          <li>Delete your personal information from our records; and</li>
          <li>
            Direct any service providers to delete your personal information
            from their records.
          </li>
        </ul>
        <p>
          Please note that we may not be able to comply with requests to delete
          your personal information if it is necessary to:
        </p>
        <ul>
          <li>
            Complete the transaction for which the personal information was
            collected, fulfill the terms of a written warranty or product recall
            conducted in accordance with federal law, provide a good or service
            requested by you, or reasonably anticipated within the context of
            our ongoing business relationship with you, or otherwise perform a
            contract between you and us;
          </li>
          <li>
            Detect security incidents, protect against malicious, deceptive,
            fraudulent, or illegal activity; or prosecute those responsible for
            that activity;
          </li>
          <li>
            Debug to identify and repair errors that impair existing intended
            functionality;
          </li>
          <li>
            Exercise free speech, ensure the right of another consumer to
            exercise his or her right of free speech, or exercise another right
            provided for by law;
          </li>
          <li>
            Comply with the California Electronic Communications Privacy Act;
          </li>
          <li>
            Engage in public or peer-reviewed scientific, historical, or
            statistical research in the public interest that adheres to all
            other applicable ethics and privacy laws, when our deletion of the
            information is likely to render impossible or seriously impair the
            achievement of such research, provided we have obtained your
            informed consent;
          </li>
          <li>
            Enable solely internal uses that are reasonably aligned with your
            expectations based on your relationship with us;
          </li>
          <li>Comply with an existing legal obligation; or</li>
          <li>
            Otherwise use your personal information, internally, in a lawful
            manner that is compatible with the context in which you provided the
            information.
          </li>
        </ul>

        <h2 className="second-title">Children Under Thirteen</h2>
        <p>
          TLDR Parents does not knowingly collect personally identifiable
          information from children under the age of thirteen. If you are under
          the age of thirteen, you must ask your parent or guardian for
          permission to use this application.
        </p>

        <h2 className="second-title">
          Disconnecting your TLDR Parents Account from Third Party Websites
        </h2>
        <p>
          You will be able to connect your TLDR Parents account to third party
          accounts. BY CONNECTING YOUR TLDR PARENTS ACCOUNT TO YOUR THIRD PARTY
          ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO THE
          CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN ACCORDANCE
          WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY SITES). IF YOU DO NOT
          WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY IDENTIFYING
          INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THIS FEATURE. You
          may disconnect your account from a third party account at any time.
          Users may disconnect their Google account by navigating to
          https://myaccount.google.com/security. This link is also posted in
          TLDR Parents under My Settings.
        </p>

        <h2 className="second-title">E-mail Communications</h2>
        <p>
          From time to time, TLDR Parents may contact you via email for the
          purpose of providing announcements, promotional offers, alerts,
          confirmations, surveys, and/or other general communication. In order
          to improve our Services, we may receive a notification when you open
          an email from TLDR Parents or click on a link therein.
        </p>
        <p>
          If you would like to stop receiving marketing or promotional
          communications via email from TLDR Parents, you may opt out of such
          communications by Update preferences in the TLDR Parents app.
        </p>

        <h2 className="second-title">External Data Storage Sites</h2>
        <p>
          We may store your data on servers provided by third party hosting
          vendors with whom we have contracted.
        </p>

        <h2 className="second-title">Changes to this Statement</h2>
        <p>
          TLDR Parents reserves the right to change this Privacy Policy from
          time to time. We will notify you about significant changes in the way
          we treat personal information by sending a notice to the primary email
          address specified in your account, by placing a prominent notice on
          our application, and/or by updating any privacy information. Your
          continued use of the application and/or Services available after such
          modifications will constitute your: (a) acknowledgment of the modified
          Privacy Policy; and (b) agreement to abide and be bound by that
          Policy.
        </p>

        <h2 className="second-title">Contact Information</h2>
        <p>
          TLDR Parents welcomes your questions or comments regarding this
          Statement of Privacy. If you believe that TLDR Parents has not adhered
          to this Statement, please contact TLDR Parents at:
        </p>
        <p>TLDR Parents, Inc.</p>
        <p>support@tldrparents.com</p>
        <p>Effective as of October 27, 2023</p>
      </SpaceAroundCardWithColorBorder>
      <div style={{ height: 30 }} />
    </Fade>
  );
};

export default Privacy;
