import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";

const useTldrTracking = () => {
  const [searchParams] = useSearchParams();
  const [searchParamsDict, setSearchParamsDict] = useState(null);
  const [tldrTrackingDictInitial, setTldrTrackingDictInitial] = useState(null);
  const [cookies, setCookie] = useCookies(["tldr_tracking"]);

  useEffect(() => {
    if (searchParams) {
      const searchParamsLocal = new URLSearchParams(searchParams);
      const paramsDict = {};
      searchParamsLocal.forEach((value, key) => {
        paramsDict[key] = value;
      });
      setSearchParamsDict(paramsDict);
    }
  }, [searchParams]);

  useEffect(() => {
    if (cookies) {
      const tldrTrackingLocal = cookies["tldr_tracking"] || null;
      if (tldrTrackingLocal) {
        setTldrTrackingDictInitial(tldrTrackingLocal);
      }
    }
  }, [cookies]);

  useEffect(() => {
    if (searchParamsDict && Object.keys(searchParamsDict).length > 0) {
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 30);
      if (tldrTrackingDictInitial) {
        let requiresUpdate = false;
        for (const [key, value] of Object.entries(searchParamsDict)) {
          if (tldrTrackingDictInitial[key] !== value) {
            requiresUpdate = true;
          }
        }
        if (requiresUpdate) {
          const tempDict = { ...tldrTrackingDictInitial, ...searchParamsDict };
          setCookie("tldr_tracking", tempDict, {
            domain: ".tldrparents.com",
            path: "/",
            secure: true,
            sameSite: "Lax",
            expires: expiryDate,
          });
        }
      } else {
        setCookie("tldr_tracking", searchParamsDict, {
          domain: ".tldrparents.com",
          path: "/",
          secure: true,
          sameSite: "Lax",
          expires: expiryDate,
        });
      }
    }
  }, [tldrTrackingDictInitial, searchParamsDict, setCookie]);

  return { trackingCookieValue: cookies["tldr_tracking"] };
};

export default useTldrTracking;
