import React from "react";
import { Divider, Layout, Space } from "antd";
import horizontalLogo from "../images/tldrparents-logo-horizontal.svg";
import { Link, Outlet, ScrollRestoration } from "react-router-dom";
import MaxWidthWrapper from "./maxWidthWrapper";
import NavMenu from "./utilityComponents/navMenu";
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import useTldrTracking from "../hooks/useTldrTracking";

const { Header, Footer, Content } = Layout;

const headerStyle = {
  top: 0,
  zIndex: 998,
  padding: 0,
  width: "100%",
  height: 80,
  lineHeight: "64px",
  backgroundColor: "#ffffff",
  position: "sticky",
};

const contentStyle = {
  minHeight: 300,
  backgroundColor: "#fff",
};

const footerStyle = {
  color: "#ffffff",
  textAlign: "center",
  lineHeight: "30px",
  backgroundColor: "#2f2f2f",
  paddingBottom: "50px",
};

const LayoutChrome = () => {
  const { trackingCookieValue } = useTldrTracking();

  return (
    <Layout style={{ backgroundColor: "#fff" }}>
      <ScrollRestoration />
      <Header style={headerStyle} /*className="breakpoint-indicator"*/>
        <MaxWidthWrapper maxWidth={1400}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link to="/">
              <img
                style={{
                  height: 80,
                  width: "auto",
                }}
                src={horizontalLogo}
                alt="TLDR Parents"
              />
            </Link>
            <NavMenu />
          </div>
        </MaxWidthWrapper>
      </Header>
      <Content style={contentStyle}>
        <Outlet />
      </Content>
      <Footer style={footerStyle}>
        <MaxWidthWrapper maxWidth={1400}>
          <div
            className="footer-text"
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            <p style={{ textAlign: "left" }}>
              At TLDR Parents, our mission is to create intelligent, AI-driven
              software that keeps parents informed, organized, and happy with
              minimal effort.
            </p>
          </div>
          <div style={{ marginBottom: 10, color: "#ffffff" }}>
            <Link to="/privacy" className="footer-text">
              Privacy
            </Link>
            <Divider
              type="vertical"
              style={{ borderRight: "1px solid #fff" }}
            />
            <Link to="/terms" className="footer-text">
              Terms
            </Link>
            <Divider
              type="vertical"
              style={{ borderRight: "1px solid #fff" }}
            />
            <Link to="/google-api-disclosure" className="footer-text">
              Google API Disclosure
            </Link>
            <Divider
              type="vertical"
              style={{ borderRight: "1px solid #fff" }}
            />
            <Link to="/data-deletion" className="footer-text">
              Data Deletion
            </Link>
          </div>

          <Space style={{ marginBottom: 5 }}>
            <a
              href="https://twitter.com/tldrparents"
              style={{ color: "#fff" }}
              target="_blank"
              rel="noreferrer"
            >
              <TwitterOutlined style={{ fontSize: 25 }} />
            </a>
            <a
              href="https://www.facebook.com/tldrparents"
              style={{ color: "#fff" }}
              target="_blank"
              rel="noreferrer"
            >
              <FacebookOutlined style={{ fontSize: 25 }} />
            </a>
            <a
              href="https://www.instagram.com/tldrparents"
              style={{ color: "#fff" }}
              target="_blank"
              rel="noreferrer"
            >
              <InstagramOutlined style={{ fontSize: 25 }} />
            </a>
            <a
              href="https://www.youtube.com/@tldrparents"
              style={{ color: "#fff" }}
              target="_blank"
              rel="noreferrer"
            >
              <YoutubeOutlined style={{ fontSize: 25 }} />
            </a>
            {/*<a*/}
            {/*  href="https://www.pinterest.com/tldrparents/"*/}
            {/*  style={{ color: "#fff" }}*/}
            {/*  target="_blank"*/}
            {/*  rel="noreferrer"*/}
            {/*>*/}
            {/*  <XXXXXX style={{ fontSize: 25 }} />*/}
            {/*</a>*/}
            {/*<a*/}
            {/*  href="https://www.tiktok.com/@tldrparents"*/}
            {/*  style={{ color: "#fff" }}*/}
            {/*  target="_blank"*/}
            {/*  rel="noreferrer"*/}
            {/*>*/}
            {/*  <XXXXXX style={{ fontSize: 25 }} />*/}
            {/*</a>*/}
            {/*<a*/}
            {/*  href="https://www.linkedin.com/company/tldrparents"*/}
            {/*  style={{ color: "#fff" }}*/}
            {/*  target="_blank"*/}
            {/*  rel="noreferrer"*/}
            {/*>*/}
            {/*  <LinkedinOutlined style={{ fontSize: 25 }} />*/}
            {/*</a>*/}
          </Space>
          <div>
            <div className="footer-text">© 2024 TLDR Parents Inc</div>
          </div>
        </MaxWidthWrapper>
      </Footer>
    </Layout>
  );
};

export default LayoutChrome;
