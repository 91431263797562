import React from "react";
import { Col, Row } from "antd";

const TopHeader = ({ header }) => {
  return (
    <Row
      justify="space-around"
      style={{
        padding: "15px 10px 0 10px",
        backgroundColor: "#ff4081",
        width: "100%",
      }}
    >
      <Col
        style={{
          maxWidth: 1400,
          width: "100%",
        }}
      >
        <h1
          className="main-title-light"
          style={{ textAlign: "center", backgroundColor: "#ff4081" }}
        >
          {header}
        </h1>
      </Col>
    </Row>
  );
};

export default TopHeader;
