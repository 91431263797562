import React from "react";
import { Card, Carousel } from "antd";

import useScreenSizeAndOrientation, {
  SCREEN_SIZE_EXTRA_SMALL,
  SCREEN_SIZE_MEDIUM,
  SCREEN_SIZE_SMALL,
} from "../../hooks/useScreenSizeAndOrientation";

const Attribution = ({ name }) => {
  return (
    <div
      style={{
        paddingTop: 20,
        fontSize: "1.1rem",
        textAlign: "right",
        color: "#ff4081",
      }}
    >
      {name}
    </div>
  );
};

const Quote = ({ quote }) => {
  return (
    <div style={{ fontSize: "1.25rem", fontWeight: "bold", height: 200 }}>
      <q>{quote}</q>
    </div>
  );
};

const Testimonials = () => {
  const { size } = useScreenSizeAndOrientation();

  const getNumCardsToShow = () => {
    if ([SCREEN_SIZE_EXTRA_SMALL, SCREEN_SIZE_SMALL].includes(size)) {
      return 1;
    }
    if ([SCREEN_SIZE_MEDIUM].includes(size)) {
      return 2;
    }
    return 3;
  };

  return (
    <Carousel
      dots={false}
      infinite={true}
      autoplay={true}
      slidesToShow={getNumCardsToShow()}
      style={{ margin: "25px 0" }}
    >
      <div>
        <Card style={{ margin: 15, height: 300 }}>
          <Quote
            quote={
              "Managing school emails used to be a nightmare, but now it's actually fun. I love that you can get so much done with a single click."
            }
          />
          <Attribution name={"Bill R."} />
        </Card>
      </div>
      <div>
        <Card style={{ margin: 15, height: 300 }}>
          <Quote
            quote={
              "As a working mom, I appreciate anything that saves me time. TLDR Parents is like having a personal assistant for school-related stuff."
            }
          />
          <Attribution name={"Sarah S."} />
        </Card>
      </div>
      <div>
        <Card style={{ margin: 15, height: 300 }}>
          <Quote
            quote={
              "TLDR Parents has simplified my family's life immensely. I've generally been the one to manage school emails in our household. It's awesome that I can build boards with everything my wife needs to keep up."
            }
          />
          <Attribution name={"Anthony E."} />
        </Card>
      </div>
      <div>
        <Card style={{ margin: 15, height: 300 }}>
          <Quote
            quote={
              "One surprising benefit for me is that I'm finding things I would have missed in the past. TLDR Parents puts the stuff I care about front and center."
            }
          />
          <Attribution name={"Katherine A."} />
        </Card>
      </div>
      <div>
        <Card style={{ margin: 15, height: 300 }}>
          <Quote
            quote={
              "I love the graphical link previews! Things that used to be buried under a 'click here' link are now displayed beautifully and with context."
            }
          />
          <Attribution name={"Anna G."} />
        </Card>
      </div>
    </Carousel>
  );
};

export default Testimonials;
