import React, { useEffect, useState } from "react";
import familyImage from "../../images/family.png";
import { Button, Col, Space, Row, Modal, Image, Card } from "antd";
import Fade from "react-reveal/Fade";
import Testimonials from "../utilityComponents/testimonials";
import SpaceAroundCardWithColorBorder from "../utilityComponents/spaceAroundCardWithColorBorder";
import ResponsiveColumn from "../utilityComponents/responsiveColumn";
import SimplifyYourLife from "../utilityComponents/simplifyYourLife";
import useLoginButton from "../../hooks/useLoginButton";
import iPhoneEventsGenie from "../../images/iphone-events-genie.png";
import iPhoneSchoolNewsletter from "../../images/iphone-school-newsletter.png";
import DoubleOrSingleCol from "../utilityComponents/doubleOrSingleCol";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const Home = () => {
  const { onClickLogin } = useLoginButton();
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  useEffect(() => {
    document.title =
      "Automatically turn school email newsletters into calendar events | TLDR Parents";
  }, []);

  return (
    <>
      <Modal
        closeIcon={null}
        width={"100%"}
        style={{ maxWidth: 800 }}
        open={videoModalOpen}
        footer={null}
        destroyOnClose={true}
        onCancel={() => setVideoModalOpen(false)}
      >
        <div className={"iframe-wrapper"}>
          <iframe
            src="https://www.youtube.com/embed/yKS03QWh-OI?si=JDPMY5WoylRVrdkB&autoplay=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
      <Fade>
        <Row
          justify="space-around"
          style={{
            padding: "40px 10px",
            backgroundColor: "#ff4081",
            width: "100%",
          }}
        >
          <Col
            style={{
              maxWidth: 1400,
              width: "100%",
            }}
          >
            <Row>
              <ResponsiveColumn
                largeSpan={12}
                largeOrder={1}
                smallSpan={24}
                smallOrder={2}
                style={{
                  width: "100%",
                  textAlign: "left",
                  paddingLeft: "20px",
                  paddingTop: 50,
                  paddingBottom: 25,
                }}
              >
                <img
                  className={"hide-on-small-screen"}
                  style={{
                    width: "90%",
                    maxWidth: 500,
                    transform: "rotate(-5deg)",
                  }}
                  src={familyImage}
                  alt="Happy TLDR Family"
                />
              </ResponsiveColumn>
              <ResponsiveColumn
                largeSpan={12}
                largeOrder={2}
                smallSpan={24}
                smallOrder={1}
                style={{
                  width: "100%",
                  textAlign: "left",
                  paddingTop: 50,
                  paddingBottom: 25,
                }}
              >
                <h1 className="main-title-light">
                  Automatically turn school email newsletters into calendar
                  events
                </h1>
                <p className="third-title-light">
                  TLDR Parents is your ultimate sidekick in conquering
                  never-ending school and extracurricular emails
                </p>
                <Space size={"large"}>
                  <Card
                    hoverable={true}
                    style={{
                      backgroundColor: "#fff",
                      width: 200,
                    }}
                    bodyStyle={{ padding: 10 }}
                    onClick={() => {
                      setVideoModalOpen(true);
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <PlayArrowIcon sx={{ fontSize: 75, color: "#1ac635" }} />
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        color: "#168dff",
                        fontSize: "1.5rem",
                      }}
                    >
                      Watch the Video
                    </div>
                  </Card>
                  <Button
                    className="ga-sign-up-button"
                    type="primary"
                    size={"large"}
                    onClick={() => {
                      onClickLogin();
                    }}
                  >
                    Sign up for FREE
                  </Button>
                </Space>
                <p style={{ paddingTop: 10, color: "#fff" }}>
                  Want help getting started? Create an account, and then email
                  us at help@tldrparents.com. We'll set up a 20 minute Zoom!
                </p>
              </ResponsiveColumn>
            </Row>
          </Col>
        </Row>
        <Row
          justify="space-around"
          style={{
            padding: "40px 10px",
            width: "100%",
          }}
        >
          <Col
            style={{
              maxWidth: 1400,
              width: "100%",
            }}
          >
            <SimplifyYourLife />
          </Col>
        </Row>

        <Row
          justify="space-around"
          style={{
            backgroundColor: "#5abfff",
            width: "100%",
          }}
        >
          <Col
            style={{
              maxWidth: 1400,
              width: "100%",
            }}
          >
            <Testimonials />
          </Col>
        </Row>

        <div style={{ height: 30 }} />

        <Row
          justify="space-around"
          style={{
            padding: "0 10px",
            backgroundColor: "#fff",
            width: "100%",
          }}
        >
          <Col
            style={{
              maxWidth: 1400,
              width: "100%",
            }}
          >
            <Row gutter={[16, 16]}>
              <DoubleOrSingleCol>
                <h2 className={"second-title"} style={{ color: "#9c27b0" }}>
                  Transform this...
                </h2>
                <Card style={{ borderColor: "#9c27b0", textAlign: "center" }}>
                  <Image
                    src={iPhoneSchoolNewsletter}
                    preview={false}
                    style={{ maxWidth: 450 }}
                  />
                </Card>
              </DoubleOrSingleCol>
              <DoubleOrSingleCol>
                <h2 className={"second-title"} style={{ color: "#9c27b0" }}>
                  ...to this
                </h2>
                <Card style={{ borderColor: "#9c27b0", textAlign: "center" }}>
                  <Image
                    src={iPhoneEventsGenie}
                    preview={false}
                    style={{ maxWidth: 450 }}
                  />
                </Card>
              </DoubleOrSingleCol>
            </Row>
          </Col>
        </Row>

        <div style={{ height: 30 }} />

        <Row
          justify="space-around"
          style={{
            padding: "0 10px",
            backgroundColor: "#fff",
            width: "100%",
          }}
        >
          <Col
            style={{
              maxWidth: 1400,
              width: "100%",
            }}
          >
            <h2
              className={"second-title"}
              style={{ color: "#ff6f00", marginBottom: 0 }}
            >
              What does "TLDR" mean?
            </h2>
          </Col>
        </Row>

        <SpaceAroundCardWithColorBorder borderColor={"#ff6f00"}>
          <div style={{ fontSize: "1.5rem" }}>
            <a
              href="https://www.merriam-webster.com/dictionary/TL%3BDR"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "underline", color: "#000" }}
            >
              TLDR
            </a>{" "}
            stands for "Too Long; Didn't Read." It's a snappy shorthand for
            those moments when something is just too lengthy to read in full. In
            essence, TLDR is all about providing a concise summary that hits the
            main points or key message of a much longer piece of content.
            Perfect for when you need the gist without the grind!
          </div>
        </SpaceAroundCardWithColorBorder>
        <div style={{ height: 40 }} />
      </Fade>
    </>
  );
};

export default Home;
