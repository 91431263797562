import React from "react";
import { Card, Col, Row } from "antd";

const SpaceAroundCardWithColorBorder = ({ borderColor, children }) => {
  return (
    <Row
      justify="space-around"
      style={{
        padding: "15px 10px 0 10px",
        backgroundColor: "#fff",
        width: "100%",
      }}
    >
      <Col
        style={{
          maxWidth: 1400,
          width: "100%",
        }}
      >
        <Card style={{ border: `1px solid ${borderColor}` }}>{children}</Card>
      </Col>
    </Row>
  );
};

export default SpaceAroundCardWithColorBorder;
