import { createBrowserRouter } from "react-router-dom";
import Home from "./components/mainPages/home";
import About from "./components/mainPages/about";
import Features from "./components/mainPages/features";
import Pricing from "./components/mainPages/pricing";
import LayoutChrome from "./components/layoutChrome";
import Privacy from "./components/legalPages/privacy";
import Terms from "./components/legalPages/terms";
import Support from "./components/legalPages/support";
import GoogleApiDisclosure from "./components/legalPages/googleApiDisclosure";
import DataDeletion from "./components/legalPages/dataDeletion";
import TipsAndMore from "./components/mainPages/tipsAndMore";
import NotFoundOrErrorPage from "./components/notFoundOrErrorPage";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <NotFoundOrErrorPage />,
    element: <LayoutChrome />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "features",
        element: <Features />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "tips",
        element: <TipsAndMore />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "privacy",
        element: <Privacy />,
      },
      {
        path: "data-deletion",
        element: <DataDeletion />,
      },
      {
        path: "terms",
        element: <Terms />,
      },
      {
        path: "google-api-disclosure",
        element: <GoogleApiDisclosure />,
      },
      {
        path: "support",
        element: <Support />,
      },
    ],
  },
]);

export default router;
