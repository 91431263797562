import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import TopHeader from "../utilityComponents/topHeader";
import SpaceAroundCardWithColorBorder from "../utilityComponents/spaceAroundCardWithColorBorder";

const DataDeletion = () => {
  useEffect(() => {
    document.title = "Data Deletion | TLDR Parents";
  }, []);

  return (
    <Fade>
      <TopHeader header={"It's your data, not ours!"} />
      <SpaceAroundCardWithColorBorder borderColor={"#ff4081"}>
        <p style={{ fontSize: "1.25rem" }}>
          If you would like all your data deleted, please email us at
          support@tldrparents.com
        </p>
      </SpaceAroundCardWithColorBorder>
    </Fade>
  );
};

export default DataDeletion;
