import React from "react";

const MaxWidthWrapper = ({ children, maxWidth }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <div style={{ width: "100%", maxWidth: maxWidth }}>{children}</div>
    </div>
  );
};

export default MaxWidthWrapper;
