import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Button, Card, Col, Row, Space } from "antd";
import TopHeader from "../utilityComponents/topHeader";
import SpaceAroundCardWithColorBorder from "../utilityComponents/spaceAroundCardWithColorBorder";
import DoubleOrSingleCol from "../utilityComponents/doubleOrSingleCol";
import useLoginButton from "../../hooks/useLoginButton";

const Pricing = () => {
  const { onClickLogin } = useLoginButton();

  useEffect(() => {
    document.title = "Pricing | TLDR Parents";
  }, []);

  const getPricingCards = () => {
    return (
      <SpaceAroundCardWithColorBorder borderColor={"#ff6f00"}>
        <Row align="middle" gutter={[16, 16]} style={{ width: "100%" }}>
          <DoubleOrSingleCol>
            <h2
              style={{
                textAlign: "center",
                color: "black",
              }}
            >
              FREE Plan
            </h2>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Card size="small" style={{ backgroundColor: "#f6f6f6" }}>
                <div
                  className="main-title-light"
                  style={{ textAlign: "center", color: "#168dff" }}
                >
                  $0 per month
                </div>
                <br />
                per family, no credit card required
              </Card>
              <Card size="small" style={{ backgroundColor: "#f6f6f6" }}>
                2 users
              </Card>
              <Card size="small" style={{ backgroundColor: "#f6f6f6" }}>
                15 emails processed per month
              </Card>
              <Card size="small" style={{ backgroundColor: "#f6f6f6" }}>
                5 boards
              </Card>
              <div style={{ marginTop: 8, textAlign: "center" }}>
                <Button
                  type="primary"
                  className="btn--accent ga-sign-up-button"
                  onClick={() => {
                    onClickLogin();
                  }}
                >
                  Get started for FREE
                </Button>
              </div>
            </Space>
          </DoubleOrSingleCol>
          <DoubleOrSingleCol>
            <h2
              style={{
                textAlign: "center",
                color: "black",
              }}
            >
              Standard Plan
            </h2>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Card size="small" style={{ backgroundColor: "#f6f6f6" }}>
                <div
                  className="main-title-light"
                  style={{ textAlign: "center", color: "#168dff" }}
                >
                  $5.99 per month
                </div>
                <br />
                per family, cancel anytime with a click
              </Card>
              <Card size="small" style={{ backgroundColor: "#f6f6f6" }}>
                5 users
              </Card>
              <Card size="small" style={{ backgroundColor: "#f6f6f6" }}>
                150 emails processed per month
              </Card>
              <Card size="small" style={{ backgroundColor: "#f6f6f6" }}>
                unlimited boards
              </Card>
              <div style={{ marginTop: 8, textAlign: "center" }}>
                <Button
                  type="primary"
                  className="btn--accent ga-sign-up-button"
                  onClick={() => {
                    onClickLogin();
                  }}
                >
                  Get started for FREE
                </Button>
              </div>
            </Space>
          </DoubleOrSingleCol>
        </Row>
      </SpaceAroundCardWithColorBorder>
    );
  };

  return (
    <Fade>
      <TopHeader
        header={"Get organized for less than 15 minutes of babysitting"}
      />
      <div style={{ marginTop: 20 }} />
      <Row
        justify="space-around"
        style={{
          width: "100%",
        }}
      >
        <Col
          style={{
            maxWidth: 1400,
            width: "100%",
          }}
        >
          <Row>{getPricingCards()}</Row>
        </Col>
      </Row>
      <SpaceAroundCardWithColorBorder borderColor={"#00cc44"}>
        * Data storage limits apply, but you shouldn't have a problem unless
        you're doing something sketchy!
      </SpaceAroundCardWithColorBorder>
      <SpaceAroundCardWithColorBorder borderColor={"#9c27b0"}>
        <p className="third-title">
          Our pricing is designed around two core principles. First, we want to
          give you a chance to love the product before paying anything. Second,
          we don't want to serve annoying, intrusive ads or market your personal
          data. Our business model is simple: we aim to charge a fair price for
          the value we offer while protecting your personal information.
        </p>
        <p className="third-title">
          A quick note about "free" internet products: Free internet products
          aren't free. Instead of paying with your dollars, you pay by sharing
          personal information, which companies use to display targeted
          advertisements.
        </p>
      </SpaceAroundCardWithColorBorder>
      <div style={{ height: 30 }} />
    </Fade>
  );
};

export default Pricing;
