import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { clickSignUpEvent } from "../facebookConversionEventUtils";

const useLoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  const getOnClickFunction = () => {
    if (isAuthenticated) {
      return () => navigate(process.env.REACT_APP_BASE_URL);
    } else {
      return () => {
        clickSignUpEvent();
        loginWithRedirect();
      };
    }
  };

  return {
    loginWithRedirect,
    isAuthenticated,
    onClickLogin: getOnClickFunction(),
  };
};

export default useLoginButton;
