import React from "react";
import { Col } from "antd";

const ResponsiveColumn = ({
  largeSpan,
  largeOrder,
  smallSpan,
  smallOrder,
  style,
  children,
}) => {
  return (
    <Col
      xs={{ span: smallSpan, order: smallOrder }}
      sm={{ span: smallSpan, order: smallOrder }}
      md={{ span: smallSpan, order: smallOrder }}
      lg={{ span: largeSpan, order: largeOrder }}
      xl={{ span: largeSpan, order: largeOrder }}
      xxl={{ span: largeSpan, order: largeOrder }}
      style={style}
    >
      {children}
    </Col>
  );
};

export default ResponsiveColumn;
